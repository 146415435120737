'use client';

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { format, addDays } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import debounce from 'lodash.debounce';
import { cn } from '@/lib/utils';

import { Button } from '@/components/ui/button';
import Calendar from '@/components/atoms/calendar';
import Text from '@/components/atoms/text';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useEffect } from 'react';

function DatePicker({
	mode,
	variant,
	disabled,
	initialSelectedDays,
	placeholder,
	className,
	name,
	setData,
	value,
	disabledDays,
	dataFormat,
	onChange,
	isOneYearLimited = false,
	hasDateRange = false,
	showClearButton = false,
}) {
	if (mode === 'single') {
		// Note: without default date
		const [date, setDate] = useState(value ? value : '');
		const [showInputCloseBtn, setShowInputCloseBtn] = useState(false);

		useEffect(() => {
			setDate(value ? value : '');
		}, [value]);

		useEffect(() => {
			if (onChange) onChange(date);
		}, [date]);

		const hideCloseBtnWithDebounce = debounce(() => {
			console.log('debounce');
			setShowInputCloseBtn(false);
		}, 200);

		return (
			<Popover>
				<div className="relative flex w-full">
					<PopoverTrigger asChild>
						<Button
							variant={'outline'}
							className={cn(
								'relative sm:min-w-[380px] justify-start text-left font-normal border-formOutline focus-visible:!outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 px-3 data-[state=open]e:!outline-none data-[state=open]:ring-2 data-[state=open]:ring-formOutlineDark data-[state=open]:ring-offset-2 disabled:pointer-events-auto disabled:cursor-not-allowed disabled:bg-white disabled:text-eis-black disabled:opacity-50 w-full z-0',
								!date && 'text-eis-black',
								variant === 'filter' && 'h-8 min-w-[212px] text-xs',
								className
							)}
							disabled={disabled}
							value={date}
							{...(showClearButton && {
								onClick: (e) => {
									if (e.target.value && open && !showInputCloseBtn) {
										setShowInputCloseBtn(true);
									}
								},
							})}
						>
							<CalendarIcon className="w-4 h-4" />
							{date ? (
								format(typeof date === 'string' ? new Date(date) : date, 'd MMM, yyyy')
							) : (
								<Text type={variant === 'filter' ? 'small' : 'span'} variant="normal" className="text-formOutlineDark">
									<Text type={variant === 'filter' ? 'small' : 'span'} variant="normal" className="text-eis-black">
										{placeholder.split(':')[0]}
									</Text>
									: {placeholder.split(':')[1]}
								</Text>
							)}
						</Button>
					</PopoverTrigger>
					{showClearButton && showInputCloseBtn && !!date && (
						<div className="absolute w-6 h-6 top-2 right-3 z-[1]">
							<Button
								type="button"
								size="none"
								variant="icon"
								icon="close-circle"
								className={cn('text-eis-black hover:bg-transparent hover:opacity-75')}
								iconClassName="w-6 h-6"
								onClick={(eve) => {
									eve.preventDefault();
									eve.stopPropagation();
									setDate('');
									setData && setData(name, null);
								}}
							/>
						</div>
					)}
				</div>

				<PopoverContent
					className="w-auto p-0"
					{...(showClearButton && {
						onFocusOutside: () => {
							if (showInputCloseBtn) {
								hideCloseBtnWithDebounce();
							}
						},
					})}
				>
					<Calendar
						mode={mode}
						date={date}
						setDate={setDate}
						initialFocus
						// Note: pass "name" for <Calendar> to update the "setup_date" in its onSelect function
						name={name}
						// Note: pass "setData" for <Calendar> to update "setup_date" in its onSelect function
						setData={setData}
						disabledDays={disabledDays}
						dataFormat={dataFormat}
						setShowInputCloseBtn={setShowInputCloseBtn}
					/>
				</PopoverContent>
			</Popover>
		);
	} else {
		const [sortedDisabledDays, setSortedDisabledDays] = useState(disabledDays ?? {});
		const [date, setDate] = useState({
			from: value?.from ? (!(value.from instanceof Date) ? new Date(value.from) : value.from) : undefined,
			to: value?.to ? (!(value.to instanceof Date) ? new Date(value.to) : value.to) : undefined,
		});

		useEffect(() => {
			if (value === undefined || (value?.from === undefined && value?.to === undefined && !hasDateRange)) {
				setDate({ from: undefined, to: undefined });
			} else if (value?.from && value?.to && JSON.stringify(date) !== JSON.stringify(value)) {
				setDate({
					from: value?.from ? (!(value.from instanceof Date) ? new Date(value.from) : value.from) : undefined,
					to: value?.to ? (!(value.to instanceof Date) ? new Date(value.to) : value.to) : undefined,
				});
			}

			if (isOneYearLimited && typeof disabledDays?.after === 'undefined') {
				const oneYearFromNow = value?.from ? new Date(value?.from) : new Date();
				oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
				oneYearFromNow.setDate(oneYearFromNow.getDate() - 1); // Subtract 1 day
				if (typeof disabledDays?.after === 'undefined') {
					setSortedDisabledDays({ before: value?.from, after: oneYearFromNow });
				}
			}
		}, [JSON.stringify(value)]);

		useEffect(() => {
			if (onChange) onChange(date);
		}, [date]);

		const getDisplayDateRange = (date) => {
			if (date.from && date.to) {
				return format(date.from, 'd MMM, yyyy') + ' - ' + format(date.to, 'd MMM, yyyy');
			} else if (!date.from && date.to) {
				return '?' + ' - ' + format(date.to, 'd MMM, yyyy');
			} else if (date.from && !date.to) {
				return format(date.from, 'd MMM, yyyy') + ' - ' + '?';
			} else {
				return '';
			}
		};

		return (
			<Popover>
				<PopoverTrigger asChild>
					<Button
						variant={'outline'}
						className={cn(
							'sm:min-w-[380px] justify-start text-left font-normal border-formOutline focus-visible:!outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 px-3 data-[state=open]e:!outline-none data-[state=open]:ring-2 data-[state=open]:ring-formOutlineDark data-[state=open]:ring-offset-2 disabled:pointer-events-auto disabled:cursor-not-allowed disabled:bg-white disabled:text-eis-black disabled:opacity-50',
							!date && 'text-eis-black',
							variant === 'filter' && 'h-8 min-w-[212px] text-xs',
							className
						)}
						disabled={disabled}
					>
						<CalendarIcon className="w-4 h-4" />
						{date && date?.from ? (
							getDisplayDateRange(date)
						) : (
							<Text type={variant === 'filter' ? 'small' : 'span'} variant="normal" className="text-formOutlineDark">
								<Text type={variant === 'filter' ? 'small' : 'span'} variant="normal" className="text-eis-black">
									{placeholder.split(':')[0]}
								</Text>
								: {placeholder.split(':')[1]}
							</Text>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0">
					<Calendar
						mode={mode}
						date={date}
						setDate={setDate}
						initialFocus
						initialSelectedDays={initialSelectedDays}
						disabledDays={disabledDays && !!Object.keys(disabledDays).length ? disabledDays : sortedDisabledDays}
						dataFormat={dataFormat}
						hasDateRange={hasDateRange}
					/>
				</PopoverContent>
			</Popover>
		);
	}
}

DatePicker.propTypes = {
	dataFormat: PropTypes.string,
};

DatePicker.defaultProps = {
	dataFormat: 'd MMM, yyyy',
};

export default DatePicker;
