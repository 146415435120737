import { Calendar as CalendarContainer } from '@/components/ui/calendar';

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { format, addDays } from 'date-fns';

function Calendar({ mode, initialSelectedDays, date, setDate, name, setData, dataFormat, disabledDays, setShowInputCloseBtn, hasDateRange }) {
	const handleSelect = (e) => {
		// Note: update the date
		setDate(e);

		if (mode === 'single') {
			// Note: 1. update backend data for <FormItem> in a form
			//       2. "setData" is passed through <FormItem> in a form
			setData && setData(name, e ? format(e, dataFormat) : null);
			setShowInputCloseBtn && setShowInputCloseBtn(!!e);
		} else {
			const from = e?.from ? format(e.from, dataFormat) : undefined;
			const to = e?.to ? format(e.to, dataFormat) : undefined;
			const dateRange = from + ' - ' + to;

			// Note: 1. update backend data for <FormItem> in a form
			//       2. "setData" is passed through <FormItem> in a form
			setData && setData(name, dateRange);
		}
	};

	if (mode === 'single') {
		// Note: set up a local "date & setDate" useState value for testing in this atom
		//       (don't need any changes!!!)
		if (!date && !setDate) {
			const [date, setDate] = useState(new Date());

			return (
				<CalendarContainer
					mode={mode}
					selected={date}
					onSelect={setDate}
					className="bg-white border rounded border-eis-light-blue-1 w-max shadow-drop-shadow"
					disabledDays={disabledDays}
				/>
			);
		}

		// Note: use the "date" & "setDate" values that passing down from its parent component
		return (
			<CalendarContainer
				mode={mode}
				selected={date}
				// Note: use a custom function handleSelect(e) to handle the select event,
				//       instead of passing "setDate" to onSelect directly.
				onSelect={(e) => {
					handleSelect(e);
				}}
				className="bg-white border rounded border-eis-light-blue-1 w-max shadow-drop-shadow"
				disabledDays={disabledDays}
			/>
		);
	} else {
		// Note: set up a local "date & setDate" useState value for testing in this atom
		//       (don't need any changes!!!)
		if (!date && !setDate) {
			const startDate = new Date();
			startDate.setHours(4);
			const [date, setDate] = useState({
				from: startDate,
				to: addDays(startDate, initialSelectedDays),
			});

			return (
				<CalendarContainer
					mode={mode}
					selected={date}
					onSelect={setDate}
					className="bg-white border rounded border-eis-light-blue-1 w-max shadow-drop-shadow"
					initialFocus
					defaultMonth={date?.from}
					numberOfMonths={2}
					disabledDays={disabledDays}
					hasDateRange={hasDateRange}
				/>
			);
		}

		// Note: use the "date" & "setDate" values that passing down from its parent component
		return (
			<CalendarContainer
				mode={mode}
				selected={date}
				// Note: use a custom function handleSelect(e) to handle the select event,
				//       instead of passing "setDate" to onSelect directly.
				onSelect={(e) => {
					handleSelect(e);
				}}
				className="bg-white border rounded border-eis-light-blue-1 w-max shadow-drop-shadow"
				initialFocus
				defaultMonth={date?.from}
				numberOfMonths={2}
				disabledDays={disabledDays}
				hasDateRange={hasDateRange}
			/>
		);
	}
}

Calendar.propTypes = {
	dataFormat: PropTypes.string,
};

Calendar.defaultProps = {
	dataFormat: 'd MMM, yyyy',
};

export default Calendar;
